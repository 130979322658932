import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import "./Airlines.style.css";
import FlightSearchForm from "../../components/flight-search-form/FlightSearchForm.component";
import AirlinesLink from "../../components/linkairlines/AirlineLink.page";


const ChicagoDenver = (props) => {

    const routePath = useLocation();
    const onTop = () => {
        window.scrollTo(0, 0);
    }
    useEffect(() => {
        onTop()
    }, [routePath]);

    // return null;
    return (
        <>
            <div className='cm-section default-page'>
                <div className='cm-page-center'>
                    <h1>Chicago to Denver</h1>
                    <FlightSearchForm />
                </div>
            </div>
            <div className='cm-section'>
                <div className='cm-page-center'>


                    <p><strong>Book Cheap Flights From Chicago To Denver</strong></p>
                    <p>Colorado's capital and largest city, Denver, is located in the United States. On the western edge of the high plains, in the South Platte River Valley, is where it is situated. Beautiful and vibrant, it is a city. Make sure to reserve direct flights from ORD to DEN if you have any immediate plans to visit Denver to receive fantastic additional savings. The area is renowned for its proximity to the Rocky Mountains, the adventure activities available there, the Haunted pub, and the distinctive and delectable cuisine.</p>
                    <p>Do you intend to go from Chicago to Denver? Do you want to find the greatest deals on flights from Chicago to Denver? With the aid of HappytoBook, you can quickly compare the costs offered by multiple airlines on the same day for all of the flights from ORD to DEN.</p>
                    <h3>Get Quick Fight Tickets</h3>
                    <ul>
                        <li aria-level="1">HappytoBook also makes it simple to book flights. Even first-time users won't have trouble using the website's extremely user-friendly design to book flights from your source to your destination. If you choose to purchase with HappytoBook, you can easily find and book inexpensive flights from Chicago to Denver.</li>
                        <li aria-level="1">When you check out on HappytoBook, attractive discounts and cashback offers are waiting for you. Booking here is also quite dependable, and you can certainly anticipate getting the finest deals on flights. You can choose the best alternative among the flights offered.&nbsp;</li>
                        <li aria-level="1">Denver is a traveler's paradise where you may meet individuals from all different age groups and lifestyle preferences. The city offers excellent areas to unwind and enjoy, including parks around lakes, stunning locations, first-rate shops, and exquisite cuisine. Additionally, there are top-notch museums in this area, whose collection of unusual artifacts is sure to fascinate you.</li>
                    </ul>
                    <h3>Get an Easy And Restful Booking Experience</h3>
                    <p>By making a reservation on HappytoBook, you'll spend less time searching through several websites to find the greatest rate. You can choose the cheapest flight from a complete list of all the flights that are currently available, which are listed here one after the other. If you run into any problems when trying to book flights from Chicago to Denver, online customer service is available to help you around the clock. If you have any problems connected to payments or other transactions, you can also contact us by phone or email.</p>
                </div>
                <div className='cm-page-center'>
                    <div className="cm-mt-40 cm-flight-link cm-flex-type-2 cm-flex-wrap">
                        <AirlinesLink />
                    </div>
                </div>
            </div>
        </>
    );
}

export default ChicagoDenver;