import React from 'react';
import { Link } from 'react-router-dom';

import logo from '../../assets/images/flight/happy-logo.png';
import { useSelector } from 'react-redux';
// import { phoneNum } from "../../utils/globalVars";

import './Footer.styles.css';
import { phoneNum } from '../../utils/globalVars';

const Footer = () => {
  // const phoneNum = useSelector((state) => state.misc.contact);

  return (
    <footer className="cm-footer-container">
      <div className="cm-fixed-footer-cta cm-txt-center cm-white-bg box-shadow-2 cm-wt-600 cm-prim-bg cm-white-col">
        <p>
          Save Instantly on Unpublished Deals - CALL NOW{' '}
          {!!phoneNum && (
            <a href={`tel:${phoneNum.value}`} className="cm-gray-bg cm-sec-col">
              <i className="fa-solid fa-phone"></i>
              {phoneNum.label}
            </a>
          )}
        </p>
      </div>
      <div className="cm-footer-top cm-sec-bg cm-white-col">
        <div className="cm-page-center cm-flex-type-2 cm-flex-align-fs">
          <div className="cm-footer-col cm-wd-25 cm-lr-pad cm-footer-col1">
            <img className="cm-footer-logo" src={logo} alt="HappytoBook" />
            <p>
              HappytoBook is established to streamline the online flight
              booking experience and touch base with our customers'
              requirements. You can find great deals on flight tickets and plan
              a budget-efficient trip with our one-stop flight booking platform.
              Customer satisfaction is the center of our goals, and we ensure to
              align with your travel needs.
            </p>
          </div>
          <div className="cm-footer-col cm-wd-25 cm-lr-pad cm-footer-col2">
            <h3>Quick Links</h3>
            <ul className="cm-menu-ul">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about-us">About Us</Link>
              </li>
              <li>
                <Link to="/contact-us">Contact Us</Link>
              </li>
              <li>
                <a href="/sitemap.xml">Site Map</a>
              </li>
            </ul>
          </div>
          <div className="cm-footer-col  cm-wd-25 cm-lr-pad cm-footer-col3">
            <h3>Reach us at</h3>
            <ul className="cm-menu-ul">
              <li><span>HAPPY TO BOOK INC</span></li>
              <li><a href="tel:+1-702-710-7789"> <i className="fa-solid fa-phone"></i> +1-702-710-7789</a></li>
              {!!phoneNum && (
                <li>
                  <a href={`tel:${phoneNum.value}`}>
                    <i className="fa-solid fa-phone"></i>
                    <span>{phoneNum.label}</span>
                  </a>
                </li>
              )}
              <li>
                <a href="mailto:contact@happytobook.com">
                  <i className="fa-solid fa-envelope"></i>
                  <span>contact@happytobook.com</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fa-solid fa-location-dot"></i>
                  <address>
                    9550 S Eastern Ave. Suite 253<br /> Las Vegas, NV 89123{' '}
                  </address>
                </a>
              </li>
            </ul>
          </div>
          <div className='cm-footer-col cm-wd-25 cm-lr-pad cm-footer-col4'>
            <h3>We Accept</h3>
            <div className='cm-flex-type-1'>
              <img src='tour-image/card/amex.png' />
              <img src='tour-image/card/mas.png' />
              <img src='tour-image/card/ms.png' />
              <img src='tour-image/card/vc.png' />
              <img src='tour-image/card/visae.png' />
            </div>
            <div className='payment-type cm-mb-30'>
              <div className='payment-content'>
                <div>
                  <h4>100% Secure payments</h4>
                  <p>All major credit & debit cards accepted</p>
                </div>
              </div>
            </div>
            <div>
              <h3>Quick Links</h3>
              <div className='social-media-iconsfooter'>
                <a href=""><i class="fa-brands fa-facebook-f"></i></a>
                <a href=""><i class="fa-brands fa-twitter"></i></a>
                <a href=""><i class="fa-brands fa-google"></i></a>
                <a href=""><i class="fa-brands fa-youtube"></i></a>
                <a href=""><i class="fa-brands fa-pinterest-p"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cm-footer-bottom cm-sec-bg cm-white-col">
        <div className="cm-page-center cm-txt-center">
          <p><Link to="/">HappytoBook.com</Link>| © 2022. All rights reserved.</p>
          <p className="cm-flex-type-2 cm-flex-wrap">
            <Link to="/privacy-policy">Privacy Policy</Link> |
            <Link to="/terms-and-conditions">Terms & Conditions</Link> |
            <Link to="/disclaimer">Disclaimer</Link> |
            <Link to="/refund-policy">Refund Policy</Link>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
