import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import "./Airlines.style.css";
import FlightSearchForm from "../../components/flight-search-form/FlightSearchForm.component";
import AirlinesLink from "../../components/linkairlines/AirlineLink.page";

const UnitedAirlines = (props) => {

    const routePath = useLocation();
    const onTop = () => {
        window.scrollTo(0, 0);
    }
    useEffect(() => {
        onTop()
    }, [routePath]);

    // return null;

    return (
        <>
            <div className='cm-section united-airlines-page'>
                <div className='cm-page-center'>
                    <h1>United Airlines</h1>
                    <FlightSearchForm />
                </div>
            </div>
            <div className='cm-section'>
                <div className='cm-page-center'>

                    <p>United Airlines is one of the prominent airlines in the United States. It is also considered the third largest airline in the world ranking after American and Delta airlines. The Airline has seven main hubs. From there, hundreds of flights operates extensive domestic and international route network with a vast presence in the Asia-Pacific region. As being United Airlines passenger, you are served to your destination safely and on time. Hence It has proven popular among millions of tourists worldwide and set a standard for the travel industry.</p>
                    <h3>History of United Airlines&nbsp;</h3>
                    <p>United Airlines is a major American airline headquartered in Chicago, Illinois that was invented by aviation frontiersperson William Boeing, who started out in the airplane business in 1916. His Boeing Airplane Company, as it was then called, performed the first international postal delivery in 1919.&nbsp;</p>
                    <p>Initially, in 1928, United Airlines was incorporated as the first international mail service, which later morphed into the third-largest international airline in the world, with 721 aircraft and 86,852 crew members.&nbsp;</p>
                    <p>In 1931, UATC (United Aircraft and Transport Corporation) formed United Air Lines, Inc. to manage its airline subsidiaries. Therefore, United Airlines makes the affirmation to be the oldest commercial airline in the United States.</p>
                    <p>This airline has built a solid reputation over the years and ranked fourth among domestic US airlines in terms of domestic market share.</p>
                    <h3>Destination You can fly with United Airlines</h3>
                    <p>Flying with United Airlines provides you the opportunity to choose from approximately 4,500 daily flights to more than 300 cities. The Airline serves a vast network in the United States with more than 200 domestic destinations with the delight of comfortable seats, inflight entertainment with fast Wi-Fi connectivity.</p>
                    <h3>Book Cheap Flight Tickets with United Airlines&nbsp;</h3>
                    <p>Looking for an affordable deal? Find a cheap United Airlines flight ticket for your favorite destinations and enjoy the journey of your dream.&nbsp;</p>
                    <p>Book your ticket flawlessly and get the warm hospitality to tailor your journey experience with United Airlines.!&nbsp;</p>
                </div>
                <div className='cm-page-center'>
                    <div className="cm-mt-40 cm-flight-link cm-flex-type-2 cm-flex-wrap">
                        <AirlinesLink />
                    </div>
                </div>
            </div>
        </>
    );
}

export default UnitedAirlines;