import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import "./Airlines.style.css";
import FlightSearchForm from "../../components/flight-search-form/FlightSearchForm.component";
import AirlinesLink from "../../components/linkairlines/AirlineLink.page";


const ChicagoDallash = (props) => {

    const routePath = useLocation();
    const onTop = () => {
        window.scrollTo(0, 0);
    }
    useEffect(() => {
        onTop()
    }, [routePath]);

    // return null;
    return (
        <>
            <div className='cm-section default-page'>
                <div className='cm-page-center'>
                    <h1>Chicago to Dallas</h1>
                    <FlightSearchForm />
                </div>
            </div>
            <div className='cm-section'>
                <div className='cm-page-center'>

                    <p>Do you intend to spend your upcoming holiday in Dallas? Booking a flight is the first action we must take when planning a trip anywhere in the globe. Most of us start looking around on several websites to find the greatest offer, but this may take a lot of time and is also complicated. So which choice do you think is the best for you? You may get the finest offers on Cheap Flights from Chicago to Dallas on HappytoBook, a trustworthy service for making rapid reservations.</p>
                    <h3>Airports of Dallas&nbsp;</h3>
                    <p>The Fort Worth International Airport is where you will land if you are traveling from Chicago to Dallas via plane. One of the busiest airports in the entire globe, it. It serves 233 locations worldwide and occupies an area of 17,207 acres of land. The facilities offer 176 domestic flights inside the United States of America and 57 foreign flights.</p>
                    <h3>Best Time to Travel to Dallas</h3>
                    <ul>
                        <li aria-level="1">You may visit Dallas in March or April.</li>
                        <li aria-level="1">These months provide more agreeable weather, which is nice for travelers to take advantage of.</li>
                        <li aria-level="1">You can attend different festivals in Dallas during this season.</li>
                    </ul>
                    <h3>Best Attraction of Dallas&nbsp;</h3>
                    <ul>
                        <li aria-level="1">The Dallas Arboretum and Botanical Garden</li>
                        <li aria-level="1">The sixth-floor museum at Dealey Plaza</li>
                        <li aria-level="1">Dallas Zoo</li>
                        <li aria-level="1">Klyde Warren Park</li>
                        <li aria-level="1">Reunion Tower</li>
                        <li aria-level="1">The Dallas World Aquarium</li>
                        <li aria-level="1">Dallas Museum of Art</li>
                        <li aria-level="1">White Rock Lake</li>
                    </ul>
                    <p>All of the above are the best attractions of Dallas, but you can explore the closest areas too, as per your stake and availability of time.</p>
                    <h3>Cheap Flight Tickets from Chicago to Dallas</h3>
                    <p>The next step is to purchase your inexpensive flights from Chicago to Dallas tickets after you have chosen the month you will travel. No matter what kind of journey you are taking, flights bird makes it simple to purchase tickets. You will be able to save a significant sum of money on the tickets, which can be applied to other vacation costs. You will have direct flights from ORD to DFW. The best place to locate inexpensive reservations is HappytoBook.</p>
                </div>
                <div className='cm-page-center'>
                    <div className="cm-mt-40 cm-flight-link cm-flex-type-2 cm-flex-wrap">
                        <AirlinesLink />
                    </div>
                </div>
            </div>
        </>
    );
}

export default ChicagoDallash;