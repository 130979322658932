import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import "./Airlines.style.css";
import FlightSearchForm from "../../components/flight-search-form/FlightSearchForm.component";
import AirlinesLink from "../../components/linkairlines/AirlineLink.page";


const Aircanada = (props) => {

    const routePath = useLocation();
    const onTop = () => {
        window.scrollTo(0, 0);
    }
    useEffect(() => {
        onTop()
    }, [routePath]);

    // return null;

    return (
        <>
            <div className='cm-section air-canada-page'>
                <div className='cm-page-center'>
                    <h1>Air Canada</h1>
                    <FlightSearchForm />
                </div>
            </div>
            <div className='cm-section'>
                <div className='cm-page-center'>
                    <p>Air Canada, is the largest Canadian international and domestic airline that serves more than 48 million passengers almost every year. In fact, Air Canada was the first airline in Canada to outlaw smoking in aircraft and to provide a high-end travel experience. Today, Air Canada has more than 2000 workers and 50 aircraft, making it a major international vacation carrier.</p>
                    <h3>Destinations you can fly by Air Canada</h3>
                    <p>Fly whenever you want with flexible Air Canada flight schedules and connectors for fully hassle-free travel between Canadian cities. You can fly to seasonal locations like San Salvador, Sydney, Kelowna, Whitehorse, Liberia, and a few other places. Plans for the future include visits to Vienna, Bordeaux, Osaka, Philipsburg, Honolulu, and other places.&nbsp;</p>
                    <h3>Hubs for Air Canada&nbsp;</h3>
                    <p>Air Canada has hubs at Calgary International Airport, Montreal-Pierre Elliott Trudeau International Airport, Vancouver International Airport, and Toronto Pearson International Airport, which is the busiest of them all. Halifax Stanfield International Airport and Ottawa MacDonald-Cartier International Airport are among the priority cities.</p>
                    <h3>Toronto Pearson International Airport:</h3>
                    <p>The main international airport serving the city of Toronto is Toronto Pearson International Airport. The largest and busiest airport in Canada is located here. It is also America's second-busiest gateway for international aviation travelers. With 47.1 million passengers passing through it each year, this airport ranks as the 30th busiest in the world in terms of passenger traffic. It is 22.5 kilometers from the city of Toronto and also acts as a hub for WestJet and FedEx Express.</p>
                    <h3>Montreal-Pierre Elliott Trudeau International Airport:</h3>
                    <p>Montreal-Pierre Elliott Trudeau International Airport is the international airport serving Montreal city. This is on the Island of Montreal a distance of 20km from Montreal city. This airport is named in honor of Pierre Elliott Trudeau who was the 15th Prime Minister of Canada. This is the 3rd busiest one in terms of carrying passengers and cargo. It handles 18.16 million passengers approximately.</p>
                    <h3>Vancouver International Airport:</h3>
                    <p>Vancouver International Airport is located 12 kilometers from downtown. With 24 million passengers annually, this airport is the second busiest in all of Canada. This airport has earned numerous prestigious accolades for being the Best International Airport in 2007 and for a continuous 9 years from 2010 through 2018. Pacific Coastal Airlines, a WestJet subsidiary, has its hub here, just next to Air Canada.</p>
                    <h3>Traveling with Air Canada</h3>
                    <p>Book an Air Canada flight to enjoy the wide-body aircraft seats, personal, on-demand inflight entertainment, and luxurious economy-class cabin furnishings. You have access to a huge network of 100 routes that span 5 continents and a large range of locations worldwide.</p>
                    <p>Compare the cost of an Air Canada flight to your preferred location and enjoy an elegant flight experience.</p>
                </div>
                <div className='cm-page-center'>
                    <div className="cm-mt-40 cm-flight-link cm-flex-type-2 cm-flex-wrap">
                        <AirlinesLink />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Aircanada;