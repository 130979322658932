
import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import "./Airlines.style.css";
import FlightSearchForm from "../../components/flight-search-form/FlightSearchForm.component";
import AirlinesLink from "../../components/linkairlines/AirlineLink.page";

const TampaOrlando = (props) => {

    const routePath = useLocation();
    const onTop = () => {
        window.scrollTo(0, 0);
    }
    useEffect(() => {
        onTop()
    }, [routePath]);

    // return null;

    return (
        <>
            <div className='cm-section default-page'>
                <div className='cm-page-center'>
                    <h1>Flights from Tampa (TPA) to Orlando(MCO)</h1>
                    <FlightSearchForm />
                </div>
            </div>
            <div className='cm-section'>
                <div className='cm-page-center'>

                    <p>Are you planning to travel from Tampa to Orlando? Are you looking for a website on the internet that offers cheap flights? If so, there is no reason to worry. You can obtain information on the various airlines online with the help of HappytoBook, and we also provide direct flights to Orlando (MCO).</p>
                    <p>In order to enjoy convenient flights, picking the right airline is crucial. It is preferable to learn more about the deals, which include the dependability of their reservation service. If it's possible, try to keep track of the airline's safety record. So, if you're ready to browse for standardized online flight booking services for flights from Tampa (TPA) to Orlando (MCO), we're here to help.</p>
                    <p>Stop your flight search because the website offers some of the cheapest airfare prices. The professional advises you to save money on your transportation from Tampa (TPA) to Orlando (MCO), allowing you to spend more money on your departure. Finding airline tickets with itineraries that match your vacation itinerary will be simple. Additionally, the team provides you with all the info you require so that you may confidently schedule reservations for your family, personal, or business vacation.</p>
                    <h3>Cheap Flights from Tampa (TPA) to Orlando (MCO)&nbsp;</h3>
                    <p>The best way to find cheap airfare is to search multiple websites. We check numerous websites and fare sources simultaneously on your behalf. Whether you are looking for one-way or round-trip flights, it is better to search here. Airlines can control the cost of your flights from Tampa to Orlando (MCO) depending on the day and time that you decide to book your trip. The professional team has discovered that Tuesdays, Wednesdays, and Saturdays are frequently the best days to book flights after gathering data from several airlines.</p>
                    <h3><strong>Effortless Booking&nbsp;</strong></h3>
                    <p>The quickest way to book your trip is to search for the greatest flight, pick, and save. Online reservations are an option.</p>
                    <h3>Friendly Customer Service&nbsp;</h3>
                    <p>If you have any concerns, go to the customer service representatives so they can help you out. the phone lines are available around-the-clock for people to converse. Book your flight online now!&nbsp;</p>
                </div>
                <div className='cm-page-center'>
                    <div className="cm-mt-40 cm-flight-link cm-flex-type-2 cm-flex-wrap">
                        <AirlinesLink />
                    </div>
                </div>
            </div>
        </>
    );
}

export default TampaOrlando;


