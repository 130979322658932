import React from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useDispatch } from "react-redux";
import HomePage from "./pages/home/HomePage.page";
import NotFoundPage from "./pages/error/NotFound.page";
import CustomToast from "./components/toast/CustomToast.component";
import FlightResult from "./pages/flight-result/FlightResult.page";
import Layout from "./containers/layout/Layout.container";
import BookTicketsPage from "./pages/book-tickets/BookTickets.page";
import PrivacyPolicyPage from "./pages/legal/PrivacyPolicy.page";
import TncPage from "./pages/legal/Tnc.page";
import DisclaimerPage from "./pages/legal/Disclaimer.page";
import RefundPolicyPage from "./pages/legal/RefundPolicy.page";
import ContactPage from "./pages/contact-us/Contact.page";
import AboutPage from "./pages/about-us/About.page";
import PackagesPage from "./pages/packages/Packages.page";
import AgentDashPage from "./pages/agent-dash/AgentDash.page";
import UnitedAirline from "./pages/airlines/UnitedAirlines.page";
import QuatarAirline from "./pages/airlines/QuatarAirline.page";
import SprintAirlines from "./pages/airlines/SprintAirlines.page";
import AirIndia from "./pages/airlines/AirIndia.page";
import WashingtonAirlines from "./pages/airlines/WashingtonAirlines.page";
import TampaOrlando from "./pages/airlines/TampaOrlando.page";
import AmericalAirlines from "./pages/airlines/AmericalAirlines.page";
import Airasia from "./pages/airlines/Airasia.page";
import Philippine from "./pages/airlines/Philippine.page";
import ChicagoDallash from "./pages/airlines/Chicagoto-dallash.page";
import Dfwtolax from "./pages/airlines/Dfw-to-Iax.page";
import ChicagoDenver from "./pages/airlines/Chicago-to-Denver.page";
import Alaskaairlines from "./pages/airlines/Alaska-airlines.page";
import Aircanada from "./pages/airlines/Aircanada.page";
import Deltaairlines from "./pages/airlines/Deltaairlines.page";
import FlightAngelesOhare from "./pages/airlines/Flight-from-los-angeles-to-chicago-ohare.page";
import FlightAngelesSanfracisco from "./pages/airlines/Flight-from-los-angeles-to-sanfrascisco.page";
import Flightangelesjacksonville from "./pages/airlines/Flight-from-los-angeles-to-jacksonville.page";

import { getContactNum } from "./redux/misc/misc.action";

function App() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getContactNum());
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<HomePage />} />
            <Route path="flights" element={<FlightResult />} />
            <Route path="booking" element={<BookTicketsPage />} />
            <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="terms-and-conditions" element={<TncPage />} />
            <Route path="disclaimer" element={<DisclaimerPage />} />
            <Route path="refund-policy" element={<RefundPolicyPage />} />
            <Route path="contact-us" element={<ContactPage />} />
            <Route path="about-us" element={<AboutPage />} />
            <Route path="packages" element={<PackagesPage />} />
            <Route path="agent-dash" element={<AgentDashPage />} />
            <Route path="united-airlines" element={<UnitedAirline />} />
            <Route path="qatar-airlines" element={<QuatarAirline />} />
            <Route path="spirit-airlines" element={<SprintAirlines />} />
            <Route path="air-india" element={<AirIndia />} />
            <Route path="flight-to-washington-dc" element={<WashingtonAirlines />} />
            <Route path="tampa-to-orlando" element={<TampaOrlando />} />
            <Route path="american-airlines" element={<AmericalAirlines />} />
            <Route path="airasia" element={<Airasia />} />
            <Route path="philippine-airlines" element={<Philippine />} />
            <Route path="chicago-to-dallas" element={<ChicagoDallash />} />
            <Route path="dfw-to-lax" element={<Dfwtolax />} />
            <Route path="chicago-to-denver" element={<ChicagoDenver />} />
            <Route path="alaska-airlines" element={<Alaskaairlines />} />
            <Route path="air-canada" element={<Aircanada />} />
            <Route path="delta-airlines" element={<Deltaairlines />} />
            <Route path="flight-from-lost-angeles-to-chicago-ohare" element={<FlightAngelesOhare />} />
            <Route path="flight-from-lost-angeles-to-san-francisco" element={<FlightAngelesSanfracisco />} />
            <Route path="flight-from-orlando-to-jacksonville" element={<Flightangelesjacksonville />} />
            <Route path="*" element={<NotFoundPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
      <CustomToast />
    </div>
  );
}

export default App;
