import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import "./Airlines.style.css";
import FlightSearchForm from "../../components/flight-search-form/FlightSearchForm.component";
import AirlinesLink from "../../components/linkairlines/AirlineLink.page";


const AngelesJacksonville = (props) => {

    const routePath = useLocation();
    const onTop = () => {
        window.scrollTo(0, 0);
    }
    useEffect(() => {
        onTop()
    }, [routePath]);

    // return null;

    return (
        <>
            <div className='cm-section india-airlines-page'>
                <div className='cm-page-center'>
                    <h1>Air India</h1>
                    <FlightSearchForm />
                </div>
            </div>
            <div className='cm-section'>
                <div className='cm-page-center'>
                    <h1>Flights from Orlando (MCO) to Jacksonville (JAX)</h1>
                    <p>Northeastern Florida's largest city, Jacksonville, cordially invite you to take a break from the world and unwind on its sandy beaches. Millions of visitors visit this location to escape the bustling city and enjoy beautiful animal theme parks and gardens. You can reserve a direct flight from MCO to JAX to take advantage of this location's breathtaking splendor without breaking the bank.</p>
                    <h3>Which is the Least Expensive Route To Travel To Jacksonville (JAX) from MCO?</h3>
                    <p>Are you planning to travel to Jacksonville (JAX)? Looking for a website that offers inexpensive flights? For simpler, less expensive, and hassle-free online flight booking services, turn to HappytoBook. Find dozens of comfortable flights that are headed in your direction by searching online. Despite the fact that JAX is served by hundreds of aircraft each week, it is still possible to schedule a direct trip from MCO to JAX. Find direct flights to JAX to arrive at your destination fast and to cut down on travel time.</p>
                    <h3>What is the Right Time To Book a Flight From MCO to JAX?</h3>
                    <p>In order to enjoy convenient airlines, picking the right airline is crucial. However, depending on the day and time that you decide to book your trip, airlines can control how much tickets cost to travel from Orlando to Jacksonville (MCO to JAX). The team of specialists has discovered that Tuesdays, Wednesdays, and Saturdays are frequently the best days to book flights after gathering data from several airlines.</p>
                    <h3>Grab the Best Deals To Make Your Traveling Cheaper At HappytoBook</h3>
                    <p>Combining your Jacksonville flight with a cheap hotel or looking for a combination package to save extra money is the greatest method to reduce your travel expenses. You can save more money on flights, whether they are one-way or round-trip, by purchasing your ticket in advance or by taking advantage of last-minute specials.</p>
                    <p>There are several ways to reduce the cost of your Orlando to Jacksonville flights. When you pick HappytoBook as your partner in travel planning, you can be sure that you are obtaining the greatest options for your itinerary. In order to save significantly on your trip from Orlando (MCO) to Jacksonville (JAX), our expert wishes you to travel for less money.</p>
                    <h3>Effortless Flight Booking&nbsp;</h3>
                    <p>You can book a cheap flight from Orlando (MCO) to Jacksonville (JAX) to Jacksonville in a fast time frame. Just search, save and book the best flight with the most rapid method to book your trip.</p>
                    <h3>Trouble-Free Customer Support</h3>
                    <p>If you have any questions or would like more information about travel discounts, our customer service representatives are only a phone call away. You can reach our specialists at any time for prompt and hassle-free support.</p>
                </div>
                <div className='cm-page-center'>
                    <div className="cm-mt-40 cm-flight-link cm-flex-type-2 cm-flex-wrap">
                        <AirlinesLink />
                    </div>
                </div>
            </div>
        </>
    );
}

export default AngelesJacksonville;