import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import "./Airlines.style.css";
import FlightSearchForm from "../../components/flight-search-form/FlightSearchForm.component";
import AirlinesLink from "../../components/linkairlines/AirlineLink.page";


const SpritAirlines = (props) => {

    const routePath = useLocation();
    const onTop = () => {
        window.scrollTo(0, 0);
    }
    useEffect(() => {
        onTop()
    }, [routePath]);

    // return null;

    return (
        <>
            <div className='cm-section spirit-airlines-page'>
                <div className='cm-page-center'>
                    <h1>Spirit Airlines</h1>
                    <FlightSearchForm />
                </div>
            </div>
            <div className='cm-section'>
                <div className='cm-page-center'>

                    <p>Spirit Airlines is a low-cost airline serving the USA. It is the eighth-largest commercial airline in North America and is based in Miramar, Florida. Anticipated flights are operated by Spirit Airlines all over the United States as well as to Mexico, Latin America, South America, and the Caribbean. Bases are employed by the airline at Chicago-O&rsquo;Hare, Orlando, Atlantic City, Las Vegas, Detroit, and Fort Lauderdale.</p>
                    <p>Spirit Airlines has a 119-strong fleet and offers flights to 65 different cities. Spirit Airlines' main website can be found at www.spirit.com. You must first purchase a flight ticket in order to fly with Spirit Airlines. In-depth details regarding Spirit Airlines' origins, travel options, and customer services are provided on this page.</p>
                    <h3>History of the Spirit Airlines&nbsp;</h3>
                    <p>In the year 1964, Spirit Airlines began operations as Clippert Trucking Company. It altered its name to Ground Air Transfer, Inc. in 1974. Since 1983, it has been offering airline services. On May 29, 1992, the airline received its current name, Spirit Airlines.</p>
                    <p>Spirit Airlines' initial headquarters were in Eastpointe, Michigan, a neighborhood of Detroit. The company wanted to resettle the headquarters and options were Detroit, New Jersey, Atlantic City, and Michigan. In November 1999, Spirit Airlines locomoted its headquarters to Florida&rsquo;s Miramar, that is, in the urban area of Miami.</p>
                    <h3>Destinations you can fly with Spirit Airlines</h3>
                    <p>Spirit Airlines currently operates flights to up to 65 destinations in the United States, the Caribbean, Central America, and South America. Spirit Airlines primarily flies passengers to Kansas City, Cleveland, Boston, Dallas, Baltimore, Detroit, Chicago, Fort Myers, Atlanta, Fort Lauderdale, Cancun, New Orleans, Las Vegas, Orlando, Myrtle Beach, and Houston.</p>
                    <h3>Book cheap flight tickets with Spirit Airlines</h3>
                    <p>Spirit Airlines now allows you to purchase a flight ticket at an affordable price. Spirit Airlines offers convenient travel to every place in North America when you book your flight with them. Additionally, the booking process is quite simple and user-friendly. Within a few minutes, you can quickly book the flight ticket online from your house. All you have to do is go to Spirit Airlines' official website and enter the necessary information, such as the date of travel, the source airport, the destination airport, etc.</p>
                    <p>In order to take the edge of the cheapest deals, try to keep your travel date flexible. Check the cost of all available tickets for an entire month, and then reserve your seat on the day that offers the best price. Additionally, purchase your airline ticket two or three months before your trip. It goes without saying that prices will be higher if you purchase your ticket last minute. Due to the high volume of travelers throughout the holiday season, ticket prices also rise. These are the considerations you should make while booking your journey with Spirit Airlines.&nbsp;</p>
                </div>
                <div className='cm-page-center'>
                    <div className="cm-mt-40 cm-flight-link cm-flex-type-2 cm-flex-wrap">
                        <AirlinesLink />
                    </div>
                </div>
            </div>
        </>
    );
}

export default SpritAirlines;