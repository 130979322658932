import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import "./Airlines.style.css";
import FlightSearchForm from "../../components/flight-search-form/FlightSearchForm.component";
import AirlinesLink from "../../components/linkairlines/AirlineLink.page";

const WashingtonAirlines = (props) => {

    const routePath = useLocation();
    const onTop = () => {
        window.scrollTo(0, 0);
    }
    useEffect(() => {
        onTop()
    }, [routePath]);

    // return null;

    return (
        <>
            <div className='cm-section default-page'>
                <div className='cm-page-center'>
                    <h1>Book Cheap Flights To Washington D.C. From Anywhere</h1>
                    <FlightSearchForm />
                </div>
            </div>
            <div className='cm-section'>
                <div className='cm-page-center'>
                    <p>The bustling city of Washington, (District of Columbia), D.C., needs no introduction. Many different items can be found there, such as historical museums, national parks, state capitol buildings, and more. Take advantage of HappytoBook's top travel offers on Washington flights if you want to truly experience this location's breathtaking nature. To receive the most discounts on your vacation package, book a cheap airfare from Washington to your destination, and vice versa.</p>
                    <h3>Washington's Must-See State Attractions</h3>
                    <p>There are several landmarks in Washington, D.C., the capital of the United States. This tourist location has a lot to amaze you whether you're traveling with family, children, or friends.</p>
                    <h3>Theater and Music in Washington</h3>
                    <p>The world-famous creative and cultural boulevard in Washington, D.C., which has a long history of regional theatre, is well known. Some very well theatres, including the Howard Theater, Lincon Theater, and Bohemian Caverns, are located on Black Broadway in Washington.</p>
                    <h3>Olympic National Park</h3>
                    <p>A distinctive and varied park in Washington (IAD) protects breathtakingly beautiful scenery. This location is a great tourist paradise since you may enjoy a variety of various habitats, such as alpine highlands, temperate rainforests, and rocky ocean beaches.</p>
                    <h3>Seattle's Pike Place Market</h3>
                    <p>The market is a popular destination for consumers because it houses a variety of local vendors, shops, seafood vendors, flower vendors, street performers, and other attractions all under one roof. Both locals and tourists alike love going there. To avoid costly airfare discounts, don't wait for the flight price to decrease; instead, book affordable flight tickets to Washington (IAD) today with HappytoBook.</p>
                    <h3>Mount St. Helens National Monument</h3>
                    <p>The volcano offers paths that are open for hiking, cross-country skiing, and snowmobiling in the winter, making this a fantastic location for both learning and exploration.</p>
                    <h3>Employ HappytoBook To Get Your Flight Tickets And Save Big</h3>
                    <p>Are you an avid traveler seeking inexpensive airfare to Washington (IAD)? Let HappytoBook search for numerous airlines using just your travel dates! We at HappytoBook analyze the past two years' worth of airfare data to help you find the most cost-effective time of day to fly to Washington, D.C., so you can purchase tickets at the lowest rates. Why spend twice as much for a flight when HappytoBook has the best rates on travel to Washington, D.C.?</p>
                </div>
                <div className='cm-page-center'>
                    <div className="cm-mt-40 cm-flight-link cm-flex-type-2 cm-flex-wrap">
                        <AirlinesLink />
                    </div>
                </div>
            </div>
        </>
    );
}

export default WashingtonAirlines;