import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import "./Airlines.style.css";
import FlightSearchForm from "../../components/flight-search-form/FlightSearchForm.component";
import AirlinesLink from "../../components/linkairlines/AirlineLink.page";


const Airasia = (props) => {

    const routePath = useLocation();
    const onTop = () => {
        window.scrollTo(0, 0);
    }
    useEffect(() => {
        onTop()
    }, [routePath]);

    // return null;

    return (
        <>
            <div className='cm-section airasia-airlines-page'>
                <div className='cm-page-center'>
                <h1>Air Asia</h1>
                    <FlightSearchForm />
                </div>
            </div>
            <div className='cm-section'>
                <div className='cm-page-center'>
                   
                    <p>Malaysian airline AirAsia is a low-cost carrier. The headquarters for this are in Kuala Lumpur. In terms of both passenger capacity and fleet size, this airline is the biggest in Malaysia. More than 165 destinations are served by this airline's domestic and international flights. Thai AirAsia, AirAsia Indonesia, AirAsia Philippines, and AirAsia India are examples of sister airlines.</p>
                    <p>The registered headquarters of AirAsia is Petaling Jaya, Selangor. In the past, the New York Times referred to AirAsia as the "Pioneer" of affordable travel in Asia. Almost nine years ago, AirAsia has consistently been ranked as the top low-cost airline in the world. Jetstar Airways, SilkAir, and Tiger Airways are some of AirAsia's rivals. The tagline of the business is "Now Everyone Can Fly." The airline has a fleet of about 89 vehicles. The airline has 74 destinations it serves. Tune Groups is the airline's parent business.</p>
                    <h3>Establishment and Operation</h3>
                    <p>This airline was established on December 20, 1993, although it didn't begin flying until November 18, 1996. A five-year strategy to strengthen AirAsia and its footprint in Asia was unveiled in 2006. As a result, it was suggested to improve the routes, connect across Asia, and expand to Vietnam, Indonesia, India, and Southern China.</p>
                    <h3>AirAsia Hub</h3>
                    <p>The international airport in Kuala Lumpur will serve as AirAsia's primary hub. Kota Kinabalu International Airport, Penang International Airport, Kuching International Airport, Senai International Airport, and Langkawi International Airport are a few examples of secondary hubs.</p>
                    <h3>Kuala Lumpur International Airport:</h3>
                    <p>The primary international airport servicing the nation is Kuala Lumpur International Airport. This one predominates throughout Southeast Asia. This place is in Sepang district, roughly 45 kilometers from Kuala Lumpur city. The largest and biggest airport in Malaysia handles 58,554,627 passengers annually, ranking it as the 23rd busiest airport worldwide. Other airlines including AirAsia X, Asia Cargo Express, UPS Airlines, Malaysia Airlines, etc. use this as their hub as well.</p>
                    <h3>Kempegowda International Airport:</h3>
                    <p>The international airport serving Bangalore is Kempegowda International Airport, which covers 4000 acres of land. It is 40 kilometers away from Devanahalli. This airport, which opened in 2008 and handled 25,04 million passengers, is the third busiest in India in terms of passenger volume. Additionally, this acts as the main city for Air India and Spice Jet and the hub for other airlines including Alliance Air, Jet Airways, and Indigo.</p>
                    <h3>Kota Kinabalu International Airport:</h3>
                    <p>The international airport at Kota Kinabalu, the Malaysian state of Sabah's capital, is called Kota Kinabalu International Airport. It is uncovered 8 kilometers from the city. With 8 million passengers, this airport is Malaysia's second busiest. Other airlines including MASWings and Malaysia Airlines use this as their hub as well.</p>
                    <h3>Penang International Airport:</h3>
                    <p>One of the busiest airports in Malaysia is Penang International Airport, which is situated in George Town. Being the oldest airport in the nation and having opened in 1935, it was determined in 2009 as Airport of the Year. roughly 15 million travelers each year were handled.</p>
                    <h3>Kuching International Airport:</h3>
                    <p>Sarawak's Kuching International Airport serves international travelers. Being the fourth busiest airport in Malaysia, it is located 11 kilometers from Kuching and served 5 million passengers last year.</p>
                    <h3>Senai International Airport:</h3>
                    <p>Senai town is host to Senai International Airport, formerly known as Sultan Ismail International Airport. It carried about 3,124,799 passengers. Malindo Air uses it as a hub as well.</p>
                    <h3>Langkawi International Airport:</h3>
                    <p>Malaysia's Langkawi International Airport handled over 2,336,177 passengers last year. In the year 1945, this had been the Japanese Army's airstrip.</p>
                    <h3>Destinations covered by AirAsia</h3>
                    <p>There are flights available to destinations such as India, New Zealand, Indonesia, Thailand, China, South Korea, and the Philippines, among others.</p>
                    <p>Future lines would pass through nations including Sri Lanka, Taiwan, and others.</p>
                </div>
                <div className='cm-page-center'>
                    <div className="cm-mt-40 cm-flight-link cm-flex-type-2 cm-flex-wrap">
                        <AirlinesLink />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Airasia;