import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import "./Airlines.style.css";
import FlightSearchForm from "../../components/flight-search-form/FlightSearchForm.component";
import AirlinesLink from "../../components/linkairlines/AirlineLink.page";


const Deltaairlines = (props) => {

    const routePath = useLocation();
    const onTop = () => {
        window.scrollTo(0, 0);
    }
    useEffect(() => {
        onTop()
    }, [routePath]);

    // return null;

    return (
        <>
            <div className='cm-section delta-airlines-page'>
                <div className='cm-page-center'>
                    <h1>Delta Airlines</h1>
                    <FlightSearchForm />
                </div>
            </div>
            <div className='cm-section'>
                <div className='cm-page-center'>
                    <p>Delta Airlines commonly referred to as Delta, is one of the prominent airlines of the United States and a legacy carrier. Delta, one of the oldest operating airlines in the world, has its corporate headquarters in Atlanta, Georgia. Together with its regional affiliates, Delta offers over 5,400 daily flights to 325 locations in 52 nations on six continents.</p>
                    <h3>Destinations&nbsp;</h3>
                    <p>More than 15,000 flights are operated each day by Delta and its international alliance partners. Only Delta operates flights from the United States to Dakar, Stockholm, and Copenhagen.</p>
                    <h3>Hubs for Delta Airlines&nbsp;</h3>
                    <p>Delta Airlines currently has nine hubs:</p>
                    <ul>
                        <li aria-level="1"><strong>Atlanta</strong> <strong>- </strong>The main ideal location for Latin America and the Caribbean for Delta, as well as its hub for the Southeast of the United States. Along with its corporate headquarters, Delta also runs its major hub and key maintenance facility in Atlanta: Delta TechOps.</li>
                    </ul>
                    <ul>
                        <li aria-level="1"><strong>Boston - </strong>the secondary transatlantic hub for Delta. It provides transportation to locations in North America and Europe.</li>
                    </ul>
                    <ul>
                        <li aria-level="1"><strong>Detroit - </strong>One of Delta's two hubs in the Midwest. The main Asian entry point for the Eastern United States, it also offers access to a large number of locations in the Americas and Europe. Previously a hub for Northwest Airlines.</li>
                    </ul>
                    <ul>
                        <li aria-level="1"><strong>Los Angeles - </strong>the secondary hub for the West Coast of Delta. It provides service to major American cities, West Coast regional destinations, and cities in Latin America, Asia, Australia, and Europe. The main airline at LAX is Delta, which holds a 20% market share (as of August 2022 YTD) of the airport.</li>
                    </ul>
                    <ul>
                        <li aria-level="1"><strong>Minneapolis/St. Paul -</strong> one of the two Midwest hubs for Delta. It serves several American metropolitan destinations, a handful of regional destinations in the upper Midwest, as well as a few carefully chosen locations in Europe and Asia. It is the airline's main Canadian gateway. former hub for Northwest Airlines.</li>
                    </ul>
                    <ul>
                        <li aria-level="1"><strong>New York - JFK </strong>- the main transatlantic hub for Delta. Additionally, the hub provides service on numerous transnational "prestige routes" to Seattle, San Francisco, and Los Angeles.</li>
                    </ul>
                    <ul>
                        <li aria-level="1"><strong>New York - LaGuardia -</strong> Second hub in New York for Delta. Many East Coast U.S. cities as well as a number of regional locations in the U.S. and Canada are serviced by Delta at LaGuardia.</li>
                    </ul>
                    <ul>
                        <li aria-level="1"><strong>Salt Lake City - </strong>The hub for Delta in the American Rocky Mountain region. With a focus on the Rocky Mountains, a few chosen locations in Canada and Mexico, as well as a few chosen cities in Europe and Hawaii, Delta service is available to the majority of major U.S. destinations and a number of smaller U.S. destinations.</li>
                    </ul>
                    <ul>
                        <li aria-level="1"><strong>Seattle/Tacoma -</strong> the main West Coast hub for Delta. The hub acts as the western United States' international entry point to Asia. Numerous significant U.S. cities as well as regional locations in the Pacific Northwest are also served by Delta.</li>
                    </ul>
                    <h3>Book Cheap Flight Tickets with Delta Airlines&nbsp;</h3>
                    <p>Delta Airlines rewards its frequent customer with wonderful savings. It's really simple to purchase tickets with Delta Airlines. A few easy steps will allow you to complete it online. All you need to do is to purchase your flight ticket two or three months before the date of your trip to find inexpensive seats.</p>
                </div>
                <div className='cm-page-center'>
                    <div className="cm-mt-40 cm-flight-link cm-flex-type-2 cm-flex-wrap">
                        <AirlinesLink />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Deltaairlines;