import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import "./Airlines.style.css";
import FlightSearchForm from "../../components/flight-search-form/FlightSearchForm.component";
import AirlinesLink from "../../components/linkairlines/AirlineLink.page";


const QuatarAirline = (props) => {

    const routePath = useLocation();
    const onTop = () => {
        window.scrollTo(0, 0);
    }
    useEffect(() => {
        onTop()
    }, [routePath]);

    // return null;

    return (
        <>
            <div className='cm-section qatar-airlines-page'>
                <div className='cm-page-center'>
                    <h1>Qatar Airways</h1>
                    <FlightSearchForm />
                </div>
            </div>
            <div className='cm-section'>
                <div className='cm-page-center'>
                    <p>Qatar Airways is one of the youngest international airlines, with its headquarters in Doha. It is one of the most prominent airlines in Qatar with flights to more than 150 international destinations across Africa, Central Asia, South Asia, Europe, the Middle East, and North America from its headquartered.</p>
                    <h3>Destinations You Can Fly with Qatar Airways</h3>
                    <p>The fastest-growing airline in the world, Qatar Airways, is rapidly expanding its service areas from its foundation at Hamad International Airport in Doha, Qatar. They provide you with connections to more than 150 destinations using cutting-edge aircraft and unrivaled five-star airport services. With its excellent customer service and online check-in options, Qatar Airlines has quickly established itself as a top-tier airline in Qatar. Their extensive network practically enables you to travel everywhere in the shortest amount of time.</p>
                    <h3>Direct Connecting Routes by Qatar Airways</h3>
                    <p>The fastest-growing airline, Qatar Airways, plans frequent direct flights to these long-popular locations even though there are connecting services available, getting travelers to their preferred location without any hassle.</p>
                    <h3>Qatar Airways Baggage Allowance</h3>
                    <p>The Qatar Airways baggage allowance policy mostly depends on the destination you choose to visit and the cabin class you choose. Several travel classes and destinations have different luggage requirements. Visit their official website, www.qatarairways.com, to verify the specifics. In general, each passenger is permitted to bring one bag with a maximum weight allowance into the cabin. Don't forget to get luggage labels for your belongings.</p>
                    <h3>How To Check-in Qatar Airways</h3>
                    <p>You can web-check in using the Qatar Airways Flight booking app or the official website to sidestep the check-in lines. Often, the web check-in window opens up before 24 hours and closes before 45 minutes just before takeoff.</p>
                </div>
                <div className='cm-page-center'>
                    <div className="cm-mt-40 cm-flight-link cm-flex-type-2 cm-flex-wrap">
                        <AirlinesLink />
                    </div>
                </div>
            </div>
        </>
    );
}

export default QuatarAirline;