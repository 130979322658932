import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import "./Airlines.style.css";
import FlightSearchForm from "../../components/flight-search-form/FlightSearchForm.component";
import AirlinesLink from "../../components/linkairlines/AirlineLink.page";


const Philippine = (props) => {

    const routePath = useLocation();
    const onTop = () => {
        window.scrollTo(0, 0);
    }
    useEffect(() => {
        onTop()
    }, [routePath]);

    // return null;
    return (
        <>
            <div className='cm-section philippines-airline-page'>
                <div className='cm-page-center'>
                    <h1>Philippine Airlines</h1>
                    <FlightSearchForm />
                </div>
            </div>
            <div className='cm-section'>
                <div className='cm-page-center'>
                    <p>The national airline of the Philippines is Philippine Airlines, a trading brand of PAL Holdings, Inc. which was once known as Philippine Air Lines until 1970. The airline was established in 1941 and has its headquarters in the PNB Financial Center in Pasay. It is the first and most geriatric commercial airline in Asia and still operating under its original name.</p>
                    <h3>History of Philippine Airlines</h3>
                    <p>Philippine Airlines, formerly among the biggest airlines in Asia, suffered significantly from the 1997 Asian financial crisis. Philippine Airlines downsized its international operations by completely ending flights to Europe and the Middle East, cutting almost all domestic flights aside from those that originated in Manila, shrinking the size of its fleet, and terminating thousands of employees in one of the Philippines' biggest corporate failures.</p>
                    <p>In 1998, the airline was put into receivership; nevertheless, many of its destinations later saw a return of service. After coming out of receivership in 2007, Philippine Airlines often changed its management. However, PAL's goal to reclaim its position as one of Asia's top airlines is still in place.</p>
                    <h3>Destinations Covered by Philippine Airlines</h3>
                    <p>Ninoy Aquino International Airport serves as the primary hub for Philippine Airlines, while Mactan-Cebu International Airport, Clark International Airport, Francisco Bangoy International Airport and Kalibo International Airport serve as secondary hubs. The majority of Philippine Airlines' routes are served from Ninoy Aquino International Airport.</p>
                    <p>Philippine Airlines will run 31 domestic routes and 43 overseas destinations as of 2021. Its subsidiary PAL Express provides service to North America, the Middle East, Europe (including the United Kingdom), Australia, and New Zealand in the Asia-Pacific area (Canada and United States).</p>
                </div>
                <div className='cm-page-center'>
                    <div className="cm-mt-40 cm-flight-link cm-flex-type-2 cm-flex-wrap">
                        <AirlinesLink />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Philippine;