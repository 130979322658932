export const charges = {
  airlineTaxes: {
    DL: 12,
    AA: 14,
    UA: 13,
    NK: 15, 
    B6: 15,
    AS: 15,
    OTHER: 15,
  },
  baggage: [55, 100],
  travelProtection: 40,
};
