import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import "./Airlines.style.css";
import FlightSearchForm from "../../components/flight-search-form/FlightSearchForm.component";
import AirlinesLink from "../../components/linkairlines/AirlineLink.page";


const Alaskaairlines = (props) => {

    const routePath = useLocation();
    const onTop = () => {
        window.scrollTo(0, 0);
    }
    useEffect(() => {
        onTop()
    }, [routePath]);

    // return null;

    return (
        <>
            <div className='cm-section default-page'>
                <div className='cm-page-center'>
                    <h1>Alaska Airlines</h1>
                    <FlightSearchForm />
                </div>
            </div>
            <div className='cm-section'>
                <div className='cm-page-center'>
                    <p>Alaska Airlines is a significant American airline with its headquarters in the Seattle metropolitan region, a city in Washington. 1932 marks the company's founding year. It operated flights out of Alaska at the time and went by the name McGee Airways. Alaska Airlines currently operates flights to at least 100 locations, including Hawaii, Alaska, Mexico, Canada, and Costa Rica.</p>
                    <p>The fifth-largest airline in the United States is Alaska Airlines. It is a noteworthy air career in the country, together with Horizon Air, its sister airline. Based on customer satisfaction, J.D. Power and Associates has named it the adequate traditional airline in the US. For 11 years running, Alaska Airlines has held the top rank.</p>
                    <p>Alaska Airlines has 317 aircraft in its fleet, and Alaska Air Group is the airline's parent corporation. It transports passengers to 118 locations (both international and domestic). "That's How We Fly" is the company's catchphrase/slogan. AlaskaAir.com is the airline's official website.</p>
                    <h3>Establishment and Operation</h3>
                    <p>For almost a century, United Airlines has been providing transportation for travelers. It was established on April 6, 1926, and it started operating on March 28, 1927, five years later. Since then, it has gained recognition as one of America's most popular airlines.</p>
                    <h3>History of Alaska Airlines&nbsp;</h3>
                    <p>McGee Airways is credited for Alaska Airlines' beginnings. During the Great downturn, McGee Airways was having financial difficulties. At the time, Anchorage was home to multiple airlines, thus in order to survive, it had to cooperate with the others. Star Air Lines was its previous name. Alaska Star Lines took the place of Star Air Lines. After that, Alaska Airlines became the corporation's official name on May 2, 1944, when the company was founded. Alaska Airlines' main office at the time was in Anchorage, not Seattle.</p>
                    <h3>Employees of Alaska Airlines</h3>
                    <p>Alaska Airlines has approximately 12,998 employees, according to a survey taken in 2014. Nearly 1,550 pilots make up Alaska Airlines' pilot group, while more than 4,100 flight attendants work for the airline.</p>
                    <h3>Destinations covered by Alaska Airlines</h3>
                    <p>At least 115 locations in Canada, Mexico, Costa Rica, and the United States of America are served by Alaska Airlines. King Salmon, Anchorage, Nome, Juneau, Fairbanks, Adak, Ketchikan, Prudhoe Bay, Kotzebue, Sitka, and Kodiak are a few of the major Alaskan destinations where travelers commonly fly with Alaska Airlines. Many of these places can't be reached by car. Alaska Airlines is one of the most significant airlines operating along the West Coast of the United States. Additionally, Portland, San Diego, Alaska, and Seattle are all heavily affected by it. Alaska Airlines has service to four airports in the metropolitan area of Los Angeles as well as the three major airports in San Francisco.</p>
                    <h3>Leading Hubs of Alaska Airlines&nbsp;</h3>
                    <p>Here is a list of the top hubs of Alaska Airlines:</p>
                    <ul>
                        <li aria-level="1">Tacoma/Seattle</li>
                        <li aria-level="1">Anchorage</li>
                        <li aria-level="1">San Francisco</li>
                        <li aria-level="1">Portland (OR)</li>
                        <li aria-level="1">Los Angeles</li>
                    </ul>
                    <h3>Book Cheap Flight Tickets with Alaska Airlines&nbsp;</h3>
                    <p>Alaska Airlines rewards its frequent customer with wonderful savings. It's really simple to purchase airline tickets with Alaska Airlines. Following a few easy steps will allow you to complete it online. Try to purchase your flight ticket two or three months before the date of your trip in order to find affordable seats.</p>
                </div>
                <div className='cm-page-center'>
                    <div className="cm-mt-40 cm-flight-link cm-flex-type-2 cm-flex-wrap">
                        <AirlinesLink />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Alaskaairlines;