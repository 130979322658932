import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import "./Airlines.style.css";
import FlightSearchForm from "../../components/flight-search-form/FlightSearchForm.component";
import AirlinesLink from "../../components/linkairlines/AirlineLink.page";


const AmericanAirlines = (props) => {

    const routePath = useLocation();
    const onTop = () => {
        window.scrollTo(0, 0);
    }
    useEffect(() => {
        onTop()
    }, [routePath]);

    // return null;
    return (
        <>
            <div className='cm-section american-airlines-page'>
                <div className='cm-page-center'>
                    <h1>American Airlines</h1>
                    <FlightSearchForm />
                </div>
            </div>
            <div className='cm-section'>
                <div className='cm-page-center'>
                    <p>American Airlines, one of the biggest airlines in the world, should be on your list because it offers over 6,700 flights each day to more than 350 locations in more than 50 different countries. American Airlines is already a well-known brand when it comes to international travel, carrying more than 51 million passengers annually.</p>
                    <h3>Top Destinations To Travel With American Airlines</h3>
                    <p>With more than ten operational hubs spread across the US, including Dallas, Chicago, Charlotte, Miami, Phoenix, Washington, and Los Angeles, American Airlines transports over 140,000 people daily while providing excellent service and a welcoming environment.</p>
                    <p>American Airlines consistently aims to provide you with the shortest yet most well-liked trip maps and serves customers from all walks of life with all the central US locations. Plan a trip with American Airlines and travel anywhere in the world without fuss. On a reliable flight booking website, you can also compare American Airlines airline tickets and get the best travel offers.</p>
                    <h3>Most Prominent American Airlines Domestic Flights&nbsp;</h3>
                    <ul>
                        <li aria-level="1">New York(JFK) to Los Angeles(LAX)</li>
                        <li aria-level="1">Chicago(ORD) to New York(JFK)</li>
                        <li aria-level="1">Los Angeles(LAX) to Chicago(ORD)</li>
                        <li aria-level="1">Miami(MIA) to Los Angeles(LAX)</li>
                        <li aria-level="1">Washington(IAD) to Dallas(DAL)</li>
                    </ul>
                    <h3>Most Prominent American Airlines International Flights&nbsp;</h3>
                    <ul>
                        <li aria-level="1">New York(JFK) to London(LON)</li>
                        <li aria-level="1">New York(JFK) to Paris(PAR)</li>
                        <li aria-level="1">New York(JFK) to Helsinki(HEL)</li>
                        <li aria-level="1">Miami(MIA) to Madrid(MAD)</li>
                        <li aria-level="1">Los Angeles(LAX) to London(LON)</li>
                    </ul>
                    <h3>How to Check in with American Airlines?</h3>
                    <p>You can now check in for your flight up to 24 hours in advance. This process typically begins 24 hours before departure and ends just 45 minutes before your domestic flight takes off. The check-in process for international flights concludes earlier than 90 minutes. To avoid the inconvenience of waiting in long check-in queues, use the American Airlines flight booking app to check in for your flight and receive a digital boarding pass.</p>
                    <h3>Book Cheap Flights With American Airlines</h3>
                    <p>With the American Airlines airline booking app, you may affordably check-in for your flight and receive a digital boarding pass to avoid the inconvenience of standing in long check-in queues. With American Airlines, one of the most reliable airlines in the country, you can upgrade your travel experience while enjoying comfy seats, onboard entertainment, and complimentary food and beverages. Reduce your journey time by flying with American Airlines Flights to the location of your choice.</p>
                </div>
                <div className='cm-page-center'>
                    <div className="cm-mt-40 cm-flight-link cm-flex-type-2 cm-flex-wrap">
                        <AirlinesLink />
                    </div>
                </div>
            </div>
        </>
    );
}

export default AmericanAirlines;