import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

const AirlinesLink = () => {
    return (
        <>
            <Link to={`/air-india`}>Air India</Link>
            <Link to={`/american-airlines`}>American Airlines</Link>
            <Link to={`/alaska-airlines`}>Alaska Airlines</Link>
            <Link to={`/air-canada`}>Air Canada</Link>
            <Link to={`/airasia`}>Airasia</Link>
            <Link to={`/chicago-to-dallas`}>Chicago to Dallas</Link>
            <Link to={`/chicago-to-denver`}>Chicago to Denver</Link>
            <Link to={`/delta-airlines`}>Delta Airlines</Link>
            <Link to={`/dfw-to-lax`}>DFW to lAX</Link>
            <Link to={`/flight-from-lost-angeles-to-san-francisco`}>LAX to SFO</Link>
            <Link to={`/flight-from-lost-angeles-to-chicago-ohare`}>LAX to ORD</Link>
            <Link to={`/flight-from-orlando-to-jacksonville`}>MCO to JAX</Link>
            <Link to={`/philippine-airlines`}>Philippine Airlines</Link>
            <Link to={`/qatar-airlines`}>Qatar Airlies</Link>
            <Link to={`/spirit-airlines`}>Spirit Airlies</Link>
            <Link to={`/tampa-to-orlando`}>Tampa to Orlando</Link>
            <Link to={`/united-airlines`}>United Airlies</Link>
        </>
    );
}

export default AirlinesLink;
